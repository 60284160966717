@import "~bootstrap/scss/functions";

@font-face {
  font-family: TisaPro;
  src: url('../fonts/TisaPro.ttf');
  src: url('../fonts/TisaPro.woff') format('woff'), url("../fonts/TisaPro.woff2") format("woff2"), url('../fonts/TisaPro.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: TisaPro;
  src: url('../fonts/TisaPro-Ita.ttf');
  src: url('../fonts/TisaPro-Ita.woff') format('woff'), url("../fonts/TisaPro-Ita.woff2") format("woff2"), url('../fonts/TisaPro-Ita.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: TisaPro;
  src: url('../fonts/TisaPro-Bold.ttf');
  src: url('../fonts/TisaPro-Bold.woff') format('woff'), url("../fonts/TisaPro-Bold.woff2") format("woff2"), url('../fonts/TisaPro-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}


@font-face {
  font-family: TisaPro;
  src: url('../fonts/TisaPro-Medi.ttf');
  src: url('../fonts/TisaPro-Medi.woff') format('woff'), url("../fonts/TisaPro-Medi.woff2") format("woff2"), url('../fonts/TisaPro-Medi.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: TisaPro;
  src: url('../fonts/TisaPro-MediIta.ttf');
  src: url('../fonts/TisaPro-MediIta.woff') format('woff'), url("../fonts/TisaPro-MediIta.woff2") format("woff2"), url('../fonts/TisaPro-MediIta.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: MobiliarRealist;
  src: url('../fonts/MobiliarRealist-SemiLight.ttf');
  src: url('../fonts/MobiliarRealist-SemiLight.woff') format('woff'), url("../fonts/MobiliarRealist-SemiLight.woff2") format("woff2"), url('../fonts/MobiliarRealist-SemiLight.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: MobiliarRealist;
  src: url('../fonts/MobiliarRealist-Bold.ttf');
  src: url('../fonts/MobiliarRealist-Bold.woff') format('woff'), url("../fonts/MobiliarRealist-Bold.woff2") format("woff2"), url('../fonts/MobiliarRealist-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

$font-family-serif: 'TisaPro', serif;
$font-family-sans-serif: 'MobiliarRealist', sans-serif;
$red: #da2323;
$primary: $red;
$headings-font-family: $font-family-serif;
$btn-padding-x: 1.25rem;
$btn-padding-y: 0.5rem;
$btn-border-radius: .25rem;
$btn-line-height: 1.25rem;

html {
  -webkit-font-smoothing: antialiased;
}

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$btn-font-weight: $font-weight-bold;

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here